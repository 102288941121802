<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn v-bind="$config.buttonAttrs.floating" color="grey" @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn v-bind="$config.buttonAttrs.floating" @click="save" :disabled="loading">
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Olvasó</h1>

    <v-row>
      <v-col :cols="12" :md="6">
        <v-card :loading="loading">
          <v-card-title>
            <v-icon left>mdi-smart-card-reader-outline</v-icon>
            Alapadatok
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="Megnevezés"
              :error-messages="errors.name"
              v-model="form.name"
              filled
            />
            <v-autocomplete
              label="Kontroller"
              :items="controllers"
              item-text="name"
              item-value="element_id"
              :error-messages="errors.controller_id"
              v-model="form.controller_id"
              filled
              :value-comparator="$config.comparators.isEqual"
            />
            <v-text-field
              label="Vonal"
              :error-messages="errors.controller_line"
              v-model="form.controller_line"
              filled
            />
            <v-text-field
              label="Olvasó cím"
              :error-messages="errors.reader_address"
              v-model="form.reader_address"
              type="number"
              filled
            />
            <v-text-field
              label="Kommunikációs cím"
              :error-messages="errors.reader_comm_address"
              v-model="form.reader_comm_address"
              type="number"
              filled
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="12" :md="6">
        <v-card>
          <v-card-title>
            <v-icon left>mdi-crosshairs-gps</v-icon>
            Lokáció
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              label="Épület"
              :error-messages="errors.building_id"
              :items="buildings"
              item-text="name"
              item-value="id"
              @change="buildingChangedEventHandler"
              v-model="form.building_id"
              filled
              :value-comparator="$config.comparators.isEqual"
            />
            <v-autocomplete
              label="Épület szint"
              :error-messages="errors.building_level_id"
              :items="buildingLevels"
              item-text="name"
              item-value="id"
              v-model="form.building_level_id"
              filled
              :value-comparator="$config.comparators.isEqual"
              :placeholder="form.building_id ? '' : 'Válasszon épületet!'"
              :readonly="!form.building_id"
            />
            <v-row>
              <v-col :cols="12" :sm="6">
                <v-text-field
                  label="X koordináta"
                  :error-messages="errors.x_coord"
                  v-model="form.x_coord"
                  filled
                  v-mask="$config.inputMasks.createNumberMask({ allowDecimal: true, decimalLimit: 7 })"
                />
              </v-col>
              <v-col :cols="12" :sm="6">
                <v-text-field
                  label="Y koordináta"
                  :error-messages="errors.y_coord"
                  v-model="form.y_coord"
                  filled
                  v-mask="$config.inputMasks.createNumberMask({ allowDecimal: true, decimalLimit: 7 })"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {},
      controllers: [],
      buildings: [],
      buildingLevels: [],
      errors: [],
      loading: false,
    };
  },

  async mounted() {
    this.fetchReader();
  },

  methods: {
    async fetchReader() {
      this.loading = true;
      try {
        const response = await this.$http.get(`readers/details/${this.$route.params.id || 0}`);
        if (response.msg) {
          throw response.msg;
        }
        this.form = response.reader;
        this.fetchBuildings();
        if (this.form.building_id) {
          this.fetchBuildingLevels();
        }
        this.fetchControllers();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async fetchControllers() {
      try {
        const response = await this.$http.post('controllers/list');
        this.controllers = response.controllers;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchBuildings() {
      try {
        const response = await this.$http.post('buildings/list');
        this.buildings = response.buildings;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchBuildingLevels(reset = false) {
      if (!this.form.building_id) {
        this.form.building_level_id = 0;
        return;
      }
      try {
        const response = await this.$http.post('building-levels/list', {
          building_id: this.form.building_id,
        });
        this.buildingLevels = response.building_levels;

        if (reset) {
          this.form.building_level_id = 0;
        }
      } catch (e) {
        console.error(e);
      }
    },

    buildingChangedEventHandler() {
      this.fetchBuildingLevels(true);
    },

    async save() {
      try {
        this.errors = {};
        this.loading = true;
        const response = await this.$http.post('readers/save', this.form);
        if (Object.keys(response.errors).length) {
          this.errors = response.errors;
        } else {
          if (this.$route.name !== 'ReaderEditor') {
            this.$router.push({ name: 'ReaderEditor', params: { id: response.reader.element_id } });
          }
          this.fetchReader();
          this.$dialog.notify.success('Sikeres mentés!');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
